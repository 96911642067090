import React, {
	PropsWithChildren,
	useCallback,
	useEffect,
	useState,
} from "react";

import useTimesheetsContext from "contexts/TimesheetsContext";
import { FieldArray, Form, Formik, useFormikContext } from "formik";
import { t } from "i18next";
import moment from "moment";
import { Trans } from "react-i18next";
import { toast } from "react-toastify";

import TimesheetsService from "services/TimeSheetsService";

import useAppContext from "store/useAppContext";

import {
	ITimesheetEditFormValues,
	TimesheetEditFormValidation,
} from "./TimesheetEditFormValidation";
import { Button, Picto, Tooltip } from "@zolteam/react-ras-library";
import { Accordion, IAccordionProps, Textarea } from "components/atoms";
import { ConfirmModal } from "components/molecules";
import {
	AddTimesheetBonusMenu,
	BlockNavigation,
	ContactAgencyModal,
	RemoveTimesheetBonusModal,
	TableBonusRow,
	TableWeekRow,
	TimesheetHead,
	TimesheetTableHead,
} from "components/organisms";
import { ITimeSheet } from "components/types";

import {
	autoFillBonuses,
	checkEachDaysAsValues,
	checkExistingValuesBonuses,
	checkExistingValuesBonusesType,
	checkExistingValuesDays,
	cn,
	convertDaysBonusesHundreds,
	convertDaysBonusesString,
	getTotalRow,
	initBonus,
	initBonusesDaysHundreds,
	initDaysHundreds,
	initTimesheetDays,
	roundNumber,
} from "utils";

import {
	HOURS_DAYS_TYPES,
	HOURS_MODE,
	HUNDREDS_MODE,
	MAX_CONTACT_MESSAGE_LENGTH,
	TIMESHEET_EDIT_PERMISSION,
	TIMESHEET_ENTRY_MODE_STORAGE_KEY,
	TIMESHEET_STATUS_SENT,
	TIMESHEET_STATUS_VALIDATED,
	TIMESHEET_STATUS_WAITING,
	TIMESHEET_THEME,
	TIMESHEET_VALIDATE_PERMISSION,
} from "constants_globals";

interface ITimesheetEditFormProps {
	timesheet: ITimeSheet;
	weekDays: any;
	onSave?: (values: ITimesheetEditFormValues, formikHelpers?: any) => void;
	onSubmit: (
		values: ITimesheetEditFormValues,
		formikHelpers: any
	) => Promise<any>;
	timeMode?: string;
	onTimeModeChange?: (mode: string) => void;
	formRef?: any;
	accordionHead?: IAccordionProps;
	hideTimeMode?: boolean;
	forcedTimeMode?: string;
	blockNavigation?: boolean;
	index?: number;
	locked?: boolean;
}

export const TimesheetEditForm: React.FC<ITimesheetEditFormProps> = ({
	timesheet,
	weekDays,
	onSubmit,
	onSave = () => {},
	timeMode = HOURS_MODE,
	onTimeModeChange,
	formRef,
	accordionHead,
	hideTimeMode,
	forcedTimeMode,
	blockNavigation = true,
	index,
	locked,
}) => {
	const [TimeMode, setTimeMode] = useState(timeMode);
	const { getAgencies, getSites, hasPermissions } = useAppContext();
	const [ConfirmSubmitModal, setConfirmSubmitModal] = useState(false);
	const [ShowContactAgencyModal, setShowContactAgencyModal] = useState(false);
	const [isRemoveBonusModalOpen, setIsRemoveBonusModalOpen] = useState(false);
	const [isEntryModeModalOpen, setIsEntryModeModalOpen] = useState(false);
	const [ShowAutoFillModal, setShowAutoFillModal] = useState(false);
	const [ShowBonusesModal, setShowBonusesModal] = useState(false);
	const [ShowSTSModal, setShowSTSModal] = useState(
		timesheet.hasUnacknowledgedImportFromSTS || false
	);
	const [showUpdateModal, setShowUpdateModal] = useState(false);
	const [showAlreadySentInfoModal, setShowAlreadySentInfoModal] =
		useState(false);
	const [isEditable, setIsEditable] = useState(
		timesheet.status === "waiting"
	);
	const deletingBonus = React.useRef<any>(null);
	const FormRef = React.useRef<any>(null);
	const submitType = React.useRef<any>(null);

	const isHundredMode = TimeMode === HUNDREDS_MODE;

	const currentFormRef = typeof formRef !== "undefined" ? formRef : FormRef;

	const handleAutoFill = (mode: any, modal = true) => {
		const { values, setFieldValue } = currentFormRef.current;

		const bonuses = values.tsBonuses.reduce((acc: any, bonus: any) => {
			const newBonus = autoFillBonuses(bonus, values.tsDays, mode);
			acc.push(newBonus);
			return acc;
		}, []);
		setFieldValue("tsBonuses", bonuses);
	};

	const toggleAutoFill = () => {
		const { values } = currentFormRef.current;
		const { tsDays, tsBonuses } = values;
		const existingBonusValues = checkExistingValuesBonusesType(tsBonuses);
		const existingDaysValues = checkExistingValuesDays(
			tsDays,
			isHundredMode
		);

		if (existingDaysValues && tsBonuses?.length && existingBonusValues) {
			setShowAutoFillModal(true);
		} else if (
			existingDaysValues &&
			tsBonuses?.length &&
			!existingBonusValues
		) {
			handleAutoFill("fullFill", false);
		} else if (existingDaysValues && !tsBonuses?.length) {
			toast.error(t("timesheets.autoFillErrors.noBonuses"));
		} else {
			toast.error(t("timesheets.autoFillErrors.noValues"));
		}
	};

	const handleSTSAknowledgement = () => {
		return TimesheetsService.timesheetImportFromSts(timesheet.id, {
			...timesheet,
			hasUnacknowledgedImportFromSTS: false,
		}).then(() => {
			setShowSTSModal(false);
		});
	};

	const toggleTimeMode = useCallback(
		(mode: string) => {
			setTimeMode(mode);
			localStorage.setItem(TIMESHEET_ENTRY_MODE_STORAGE_KEY, mode);
			onTimeModeChange?.(mode);
		},
		[onTimeModeChange]
	);

	const toggleEntryMode = useCallback(() => {
		const { values, setValues } = currentFormRef.current;

		const daysHundreds = convertDaysBonusesHundreds(
			values.tsDays,
			values.tsBonuses,
			isHundredMode
		);

		const daysHours = convertDaysBonusesString(
			daysHundreds.days,
			daysHundreds.bonuses,
			!isHundredMode
		);

		const days = initTimesheetDays(daysHours.days, values.weekDays, {
			...values.timesheet,
			days: daysHours.days,
			bonuses: daysHours.bonuses,
		});

		toggleTimeMode(isHundredMode ? HOURS_MODE : HUNDREDS_MODE);
		setTimeout(() => {
			// delay to avoid formik validation errors when changing mode
			setValues({
				...values,
				tsDays: days,
				tsBonuses: initBonus(daysHours.bonuses, days, values.weekDays),
			});
		}, 100);
	}, [currentFormRef, isHundredMode, toggleTimeMode]);

	useEffect(() => {
		if (!hideTimeMode || timeMode === TimeMode) return;
		toggleEntryMode();
	}, [timeMode, TimeMode, hideTimeMode, toggleEntryMode]);

	const handleToggleEntryMode = useCallback(() => {
		const { dirty, values } = currentFormRef.current;

		const checkedValueDays = checkExistingValuesDays(
			values.tsDays,
			isHundredMode
		);
		const checkedValueBonus = checkExistingValuesBonuses(values.tsBonuses);
		if (
			values.timesheet.status === TIMESHEET_STATUS_WAITING &&
			!hideTimeMode &&
			dirty &&
			(checkedValueDays || checkedValueBonus)
		) {
			setIsEntryModeModalOpen(true);
		} else {
			toggleEntryMode();
		}
	}, [currentFormRef, isHundredMode, toggleEntryMode, hideTimeMode]);

	const handleSendForm = (values: any) => {
		//Display a warning modal
		if (
			checkEachDaysAsValues(values.tsDays, isHundredMode) &&
			!ConfirmSubmitModal
		) {
			return setConfirmSubmitModal(true);
		}

		//Display update comment modal with resend reason
		if (submitType.current === "resend") {
			submitType.current = "submit";
			return setShowUpdateModal(true);
		}

		submitType.current = "submit";
		currentFormRef.current.submitForm();
	};

	return (
		<Formik
			initialValues={{
				tsDays: initTimesheetDays(timesheet.days, weekDays, timesheet),
				tsBonuses: initBonus(
					timesheet.bonuses,
					initTimesheetDays(timesheet.days, weekDays, timesheet),
					weekDays
				),
				weekDays,
				timesheet,
				comment: timesheet.comment,
				orderReference: timesheet.orderReference || "",
			}}
			onSubmit={(values, helpers) => {
				if (submitType.current === "save")
					return onSave(values, helpers);
				return onSubmit(values, helpers);
			}}
			validationSchema={
				!locked &&
				isEditable &&
				TimesheetEditFormValidation(t, forcedTimeMode ?? TimeMode)
			}
			validateOnChange={false}
			validationOnBlur
			innerRef={currentFormRef}
		>
			{({
				values,
				isValid,
				dirty,
				isSubmitting,
				submitForm,
				resetForm,
			}) => {
				return (
					<Form
						className={cn([
							"max-w-[1500px]",
							isSubmitting && "opacity-50 pointer-events-none",
							"[&_input]:outline-none [&_textarea]:!border-neutral-200",
						])}
					>
						<FormWrapper
							accordionHead={accordionHead}
							index={index}
							locked={locked}
							timesheet={timesheet}
						>
							<div className="overflow-x-auto">
								<table className="w-[calc(100%-1px)] min-w-[1000px] border-separate border-spacing-y-2">
									<TimesheetTableHead
										weekNumber={moment(
											new Date(timesheet.startDate)
										)
											.week()
											.toString()}
										weekDays={weekDays}
										showToggle={!hideTimeMode}
										toggleEntryModeModal={() =>
											handleToggleEntryMode()
										}
										isToggled={isHundredMode}
									/>
									<tbody className=" [&_tr:not(.separator):hover]:bg-neutral-50 dark:[&_tr:not(.separator):hover]:bg-neutral-700 [&_td]:overflow-hidden [&_td:first-child]:rounded-l-xl [&_td:last-child]:rounded-r-xl">
										<TableSeparator />
										<FieldArray
											name="tsDays"
											render={() =>
												values.tsDays.length > 0 &&
												HOURS_DAYS_TYPES.filter(
													(item) =>
														(item.name !==
															"nightHours" &&
															timesheet.isFromSTS) ||
														!timesheet.isFromSTS
												).map((item) => (
													<TableWeekRow
														key={item.title}
														days={values.tsDays}
														title={item.title}
														subtitle={item.subtitle}
														name={item.name}
														editable={isEditable}
														mode={
															isHundredMode
																? HUNDREDS_MODE
																: HOURS_MODE
														}
														locked={locked}
														total={roundNumber(
															getTotalRow(
																item.name,
																initDaysHundreds(
																	values.tsDays,
																	isHundredMode
																)
															)
														)}
													/>
												))
											}
										/>

										{!locked && isEditable && (
											<>
												<TableSeparator />
												<tr className="hover:!bg-transparent">
													<td colSpan={10}>
														<div
															className={
																"flex gap-2 my-2"
															}
														>
															<Button
																type="button"
																color="primary"
																outline
																size="s"
																onClick={() => {
																	setShowBonusesModal(
																		true
																	);
																}}
																className="self-start"
															>
																{t(
																	"timesheets.edition.addBonus"
																)}
															</Button>
															{!timesheet.isFromSTS && (
																<div
																	className={
																		"row gap-2 items-center"
																	}
																>
																	<Button
																		type="button"
																		color="primary"
																		outline
																		size="s"
																		onClick={() => {
																			toggleAutoFill();
																		}}
																	>
																		{t(
																			"timesheets.edition.autoFill"
																		)}
																	</Button>
																	<Tooltip
																		trigger="mouseenter focus"
																		arrow={
																			false
																		}
																		content={t(
																			"timesheets.edition.autoFillText"
																		)}
																	>
																		<div
																			className={
																				"cursor-pointer bg-primary-500 w-5 h-5 rounded-full flex items-center justify-center text-white text-sm font-semibold"
																			}
																		>
																			?
																		</div>
																	</Tooltip>
																</div>
															)}
														</div>
													</td>
												</tr>
											</>
										)}
										<FieldArray
											name="tsBonuses"
											render={({ remove }) => (
												<>
													{values.tsBonuses.length >
														0 &&
														values.tsBonuses.map(
															(
																bonus: any,
																index: any
															) => {
																return (
																	<TableBonusRow
																		key={`${bonus.bonusId}-${bonus.id}`}
																		locked={
																			locked
																		}
																		bonus={
																			bonus
																		}
																		field={
																			"tsBonuses"
																		}
																		editable={
																			isEditable
																		}
																		mode={
																			isHundredMode
																				? HUNDREDS_MODE
																				: HOURS_MODE
																		}
																		total={roundNumber(
																			bonus.value ||
																				getTotalRow(
																					"value",
																					initBonusesDaysHundreds(
																						values
																							.tsBonuses[
																							index
																						]
																							.days,
																						isHundredMode,
																						bonus.type
																					)
																				)
																		)}
																		indexField={
																			index
																		}
																		onRemove={() => {
																			deletingBonus.current =
																				{
																					index,
																					bonus,
																				};

																			const isBonusUsed =
																				values.tsBonuses.find(
																					(
																						b: any
																					) => {
																						if (
																							b.bonusId ===
																							bonus.id
																						) {
																							return b.days.find(
																								(
																									day: any
																								) => {
																									return !!day.value;
																								}
																							);
																						}
																						return false;
																					}
																				);

																			if (
																				isBonusUsed
																			)
																				setIsRemoveBonusModalOpen(
																					true
																				);
																			else {
																				remove(
																					index
																				);
																			}
																		}}
																	/>
																);
															}
														)}
													<RemoveTimesheetBonusModal
														onConfirm={() => {
															remove(
																deletingBonus
																	.current
																	?.index
															);
														}}
														isOpen={
															isRemoveBonusModalOpen
														}
														onClose={() => {
															setIsRemoveBonusModalOpen(
																false
															);
															deletingBonus.current =
																null;
														}}
														bonusName={t(
															`timesheets.bonuses.${deletingBonus.current?.bonus.bonusId}`,
															deletingBonus
																.current?.bonus
																.name
														)}
													/>
												</>
											)}
										/>
									</tbody>
								</table>
							</div>
							<hr className="my-4" />
							<div>
								<div className="flex-wrap items-center justify-between gap-2 mb-2 row dark:text-white">
									{(isEditable || values.comment) && (
										<p className="font-medium">
											{t("timesheets.edition.comments")}
										</p>
									)}
									<Button
										type="button"
										color="transparent"
										className={cn([
											"!text-primary-500 dark:!text-primary-300",
											isEditable || values.comment
												? "ml-auto"
												: "",
										])}
										onClick={() => {
											setShowContactAgencyModal(true);
										}}
									>
										<Picto
											icon="bubble"
											className="[&_*]:!text-primary-500 dark:[&_*]:!text-primary-300"
										/>
										<span>{t("global.contactAgency")}</span>
									</Button>
								</div>
								{(isEditable || values.comment) && (
									<Textarea
										name="comment"
										maxLength={
											isEditable
												? MAX_CONTACT_MESSAGE_LENGTH
												: undefined
										}
										className={cn([
											"[&_textarea]:!bg-white dark:[&_textarea]:!bg-neutral-800 dark:[&_textarea]:!text-primary-100 dark:[&_label]:text-primary-300",
											!isEditable &&
												"[&_textarea]:!text-neutral-600 dark:[&_textarea]:!text-neutral-100",
										])}
										disabled={locked || !isEditable}
										value={
											values?.comment?.replace(
												/\n\s+/g,
												"\n"
											) || ""
										}
									/>
								)}
							</div>
							{!locked &&
								timesheet.status === "waiting" &&
								hasPermissions([
									TIMESHEET_EDIT_PERMISSION,
									TIMESHEET_VALIDATE_PERMISSION,
								]) && (
									<div className="flex-wrap gap-2 pt-6 mt-6 border-t border-neutral-200 dark:border-neutral-600 row">
										<Button
											type="button"
											color="primary"
											className="w-full md:w-auto"
											onClick={() => {
												submitType.current = "save";
												submitForm();
											}}
											outline
											disabled={!isValid || isSubmitting}
											isLoading={isSubmitting}
										>
											{t("global.save")}
										</Button>
										{hasPermissions([
											TIMESHEET_VALIDATE_PERMISSION,
										]) && (
											<Button
												type="button"
												color="primary"
												className="w-full md:w-auto"
												disabled={
													!isValid || isSubmitting
												}
												isLoading={isSubmitting}
												onClick={() => {
													handleSendForm(values);
												}}
											>
												{t("global.send")}
											</Button>
										)}
									</div>
								)}
							{!locked &&
								(timesheet.status === TIMESHEET_STATUS_SENT ||
									timesheet.status ===
										TIMESHEET_STATUS_VALIDATED) &&
								hasPermissions([
									TIMESHEET_VALIDATE_PERMISSION,
								]) && (
									<div className="flex-wrap gap-2 pt-6 mt-6 border-t border-neutral-200 dark:border-neutral-600 row">
										{isEditable ? (
											<>
												<Button
													type="button"
													color="primary"
													className="w-full md:w-auto"
													onClick={() => {
														setIsEditable(false);
														resetForm();
													}}
													outline
												>
													{t("global.cancel")}
												</Button>
												<Button
													type="button"
													color="primary"
													className="w-full md:w-auto"
													disabled={
														!isValid || isSubmitting
													}
													isLoading={isSubmitting}
													onClick={() => {
														submitType.current =
															"resend";
														handleSendForm(values);
													}}
												>
													{t("global.send")}
												</Button>
											</>
										) : (
											<Button
												type="button"
												color="primary"
												className="w-full md:w-auto"
												disabled={
													!isValid || isSubmitting
												}
												isLoading={isSubmitting}
												onClick={() => {
													timesheet.status ===
													TIMESHEET_STATUS_SENT
														? setIsEditable(true)
														: setShowAlreadySentInfoModal(
																true
														  );
												}}
											>
												{t("timesheets.modify")}
											</Button>
										)}
									</div>
								)}
							<ContactAgencyModal
								initialValues={{
									theme: TIMESHEET_THEME,
									contractKey: `${timesheet?.contractNumber}${
										timesheet?.amendmentNumber !== "0"
											? `.${timesheet?.amendmentNumber}`
											: ""
									}`,
									siteId: timesheet?.siteId,
									agencyId: timesheet?.agencyId,
									tempWorker: timesheet?.tempWorkerFullName,
								}}
								agencies={getAgencies(true)}
								sites={getSites()}
								isDisplayed={ShowContactAgencyModal}
								onClose={() => {
									setShowContactAgencyModal(false);
								}}
							/>
							<ConfirmModal
								onConfirm={() => {
									toggleEntryMode();
									setIsEntryModeModalOpen(false);
								}}
								isOpen={isEntryModeModalOpen}
								onClose={() => {
									setIsEntryModeModalOpen(false);
								}}
								title={t("global.warning")}
								size="s"
								text={t("timesheets.entryModalText")}
							/>
							<ConfirmModal
								isOpen={ShowBonusesModal}
								onConfirm={() => {
									setShowBonusesModal(false);
								}}
								onClose={() => setShowBonusesModal(false)}
								title={t("timesheets.edition.addBonus")}
								size="l"
								buttons={["confirm"]}
								confirmText={t("global.close")}
							>
								<p className="mb-4 -mt-2 text-neutral-500">
									{t("timesheets.edition.addBonusText")}
									<br />
									{t("global.instantApply")}
								</p>
								<AddTimesheetBonusMenu locked={locked} />
							</ConfirmModal>
							<ConfirmModal
								isOpen={showUpdateModal}
								onConfirm={() => {
									setShowUpdateModal(false);
									submitType.current = "submit";
									submitForm();
								}}
								onClose={() => setShowUpdateModal(false)}
								title={t("timesheets.edition.modifyTimesheets")}
								size="s"
								// buttons={["confirm"]}
								// confirmText={t("global.close")}
							>
								<>
									<p className="mb-4 -mt-2 text-neutral-500">
										{t(
											"timesheets.edition.modifyTimesheetsText"
										)}

										<br />
									</p>
									<Textarea
										name="updateComment"
										maxLength={
											isEditable
												? MAX_CONTACT_MESSAGE_LENGTH
												: undefined
										}
										className={cn([
											"[&_textarea]:!bg-white dark:[&_textarea]:!bg-neutral-800 dark:[&_textarea]:!text-primary-100 dark:[&_label]:text-primary-300",
											!isEditable &&
												"[&_textarea]:!text-neutral-600 dark:[&_textarea]:!text-neutral-100",
										])}
										disabled={locked || !isEditable}
									/>
								</>
							</ConfirmModal>
							<ConfirmModal
								onConfirm={() => {
									setShowAlreadySentInfoModal(false);
									setShowContactAgencyModal(true);
								}}
								isOpen={showAlreadySentInfoModal}
								onClose={() => {
									setShowAlreadySentInfoModal(false);
								}}
								title={t("global.warning")}
								size="s"
								text={
									<Trans
										i18nKey={
											"timesheets.alreadySentInfoText"
										}
									/>
								}
								confirmText={t("global.contactAgency")}
							/>
							<ConfirmModal
								onConfirm={() => {
									handleAutoFill("fullFill", false);
									setShowAutoFillModal(false);
								}}
								isOpen={ShowAutoFillModal}
								onClose={() => {
									setShowAutoFillModal(false);
								}}
								title={t("global.warning")}
								text={t("timesheets.autoFillModal.text")}
								confirmText={t(
									"timesheets.autoFillModal.replace"
								)}
								buttons={[
									({ onClose }) => (
										<Button
											type="button"
											color="grey"
											onClick={onClose}
											isLoading={isSubmitting}
											className="confirmButton"
										>
											{t("global.cancel")}
										</Button>
									),
									<Button
										type="button"
										color="primary"
										outline
										onClick={() => {
											handleAutoFill("halfFill", false);
											setShowAutoFillModal(false);
										}}
										isLoading={isSubmitting}
										className="confirmButton"
									>
										{t("timesheets.autoFillModal.complete")}
									</Button>,
									"confirm",
								]}
							/>
							<ConfirmModal
								isOpen={ConfirmSubmitModal}
								onClose={() => setConfirmSubmitModal(false)}
								onConfirm={() => {
									handleSendForm(values);
								}}
								title={t("global.warning")}
								text={t("timesheets.submit.emptyValues")}
								size="s"
							/>
							<ConfirmModal
								isOpen={ShowSTSModal}
								onClose={() => handleSTSAknowledgement()}
								onConfirm={() => handleSTSAknowledgement()}
								title={t("timesheets.stsModal.title")}
								size="s"
								buttons={["confirm"]}
							>
								<Trans i18nKey={"timesheets.stsModal.text"} />
							</ConfirmModal>
							{blockNavigation && (
								<BlockNavigation
									shouldPrompt={dirty && isEditable}
									forceConfirm
								/>
							)}
						</FormWrapper>
					</Form>
				);
			}}
		</Formik>
	);
};

const TableSeparator = () => (
	<tr className="hover:!bg-transparent relative h-[1.5rem]">
		<td
			colSpan={10}
			className={
				"relative after:bg-neutral-200 after:w-full after:absolute after:content-[''] after:h-[1px]"
			}
		/>
	</tr>
);

interface IFormWrapperProps extends PropsWithChildren {
	accordionHead?: IAccordionProps;
	index?: number;
	locked?: boolean;
	timesheet: ITimeSheet;
}
const FormWrapper: React.FC<IFormWrapperProps> = ({
	accordionHead,
	children,
	index,
	locked,
	timesheet,
}) => {
	const { values, setValues, dirty: isFormDirty } = useFormikContext<any>();
	const { dirtyTimesheets, updateDirty } = useTimesheetsContext();

	useEffect(() => {
		if (
			typeof index !== "undefined" &&
			isFormDirty !== dirtyTimesheets[index]
		)
			updateDirty(index, isFormDirty);
	});

	if (accordionHead) {
		return (
			<Accordion
				className="max-w-[1500px]"
				hideOnlyBody
				title={
					<div className="w-full pt-6 mr-8">
						<TimesheetHead
							currentOrderReference={timesheet.orderReference}
							timesheet={values.timesheet}
							handleOrderReference={(value: string) => {
								setValues({
									...values,
									timesheet: {
										...values.timesheet,
										orderReference: value,
									},
									orderReference: value,
								});
							}}
							showTempWorkerName
							locked={locked}
						/>
					</div>
				}
				{...accordionHead}
			>
				<div className="p-4 mt-4 border-2 rounded-3xl bg-neutral-50 border-neutral-100 ">
					{children}
				</div>
			</Accordion>
		);
	}
	return (
		<>
			<TimesheetHead
				timesheet={values.timesheet}
				currentOrderReference={timesheet.orderReference}
				handleOrderReference={(value: string) => {
					setValues({
						...values,
						timesheet: {
							...values.timesheet,
							orderReference: value,
						},
						orderReference: value,
					});
				}}
				locked={locked}
			/>
			{children}
		</>
	);
};
